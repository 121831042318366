.file-type {
  border: rem(1) solid #BDC1C0;
  border-radius: 50%;
  color: #BDC1C0;
  height: rem(30);
  width: rem(30);
}

.list-buttons {
  button {
    margin-bottom: rem(4);
    &:not(:last-child) {
      margin-right: rem(4);
    }
  }
  @media (min-width: 1200px) {
    button {
      margin-bottom: 0;
    }
  }
}
