#cookies-msg {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $gray-900;
  color: #fff;
  font-size: .875rem;
  border-radius: 0;
  margin-bottom: 0;
  padding: 1rem;
  z-index: $zindex-tooltip;
}

.video-no-cookies {
  position: relative;
  width: 100%;
  padding-top: percentage(9 / 16);
  background-repeat: no-repeat;
  &::before{
    position: absolute;
    z-index: 10;
    background: rgba(0,0,0,.75);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  & span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    padding: .5rem;
    color: white;
    z-index: 11;
    text-align: center;
  }
}