$aside-width: 320;

aside {
  padding: 0 rem(20);
  @media (max-width: 991px) {
    bottom: 0;
    max-width: rem($aside-width);
    left: -100%;
    overflow: auto;
    position: fixed;
    top: 0;
    transition: all .3s ease-in-out;
    width: 80%;
    z-index: $zindex-fixed;
  }
  width: rem($aside-width);
}

main {
  width: 100%;
  @media (min-width: 992px) {
    width: calc(100% - #{rem($aside-width)});
  }
}

#search-mobile {
  background-color: #444;
  border-radius: 50%;
  bottom: rem(15);
  color: #fff;
  height: rem(48);
  position: fixed;
  right: rem(15);
  width: rem(48);
}

@media (max-width: 991px) {
  
  .backdrop {
    bottom: 0;
    background-color: rgba(0,0,0,.75);
    left: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    transition: all .3s ease-in-out; 
    visibility: hidden;
    z-index: $zindex-sticky;
  }

  .opened {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .backdrop {
      opacity: 1;
      visibility: visible;
    }
    aside {
      left: 0;
    }
  }

}