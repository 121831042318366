/*
  # Background image with object-fit
*/

// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}

// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
   opacity: #{$opacity/100};
  }
}

.fs-14 { font-size: rem(14) }
.fs-18 { font-size: rem(18) }
.fs-2 { font-size: rem(20) }
.fs-24 { font-size: rem(24) }

.text-decoration-none { text-decoration: none !important }
.text-underline { text-decoration: underline }