// SLIDE
.home-carousel {
  .carousel-cell{
    height: 500px;
    width: 100%;
    position: relative;
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background: rgba(0,0,0,.3);
      padding: 15px;
    }
  }
}