#page-404{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px 40px;
  border-width: 3px;
  background: #f4f7f6;
  position: relative;
  border-radius: 3px;
  text-transform: uppercase;
  &::after {
    content: '';
    position: absolute;
    top: calc(-1 * 3px);
    left: calc(-1 * 3px);
    height: calc(100% + 3px * 2);
    width: calc(100% + 3px * 2);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    border-radius: calc(2 * 3px);
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}