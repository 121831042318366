header {
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px rgba(0,0,0,.5);
  @media (min-width: 992px) {
    padding: rem(8) rem(36);
  }
}

.nav-main {
  a {
    padding: rem(10) 0;
  }
  @media (min-width: 992px) {
    &:after {
      content: '–';
      color: rgba(255,255,255,.5);
      margin-left: rem(15);
      margin-right: rem(15);
    }
    li {
      &:not(:last-child) {
        margin-right: rem(20);
      }
    }
    a {
      padding: 0;
    }
  }
}


.header-top {
  @media (max-width: 991px) {
    padding: rem(6) rem(20);
  }
}

.img-bh {
  @media (max-width: 991px) {
    max-width: rem(60);
  }
}

.btn-menu {
  height: rem(48);
  width: rem(48);
  &:not(.collapsed) {
    opacity: .7;
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      &:before {
        opacity: 0;
        top: 0;
        transition: top 75ms ease,opacity 75ms ease .12s;
      }
      &:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
      }
    }
  }
}

.hamburger-box {
  display: inline-block;
  position: relative;
  width: rem(30);
  height: rem(24);
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  background-color: #fff;
  height: rem(2);
  position: absolute;
  width: rem(30);
}

.hamburger-inner {
  top: 50%;
  margin-top: -2px;
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);
  transition-duration: 75ms;
  &:before, &:after {
    content: '';
    display: block;
  }
  &:before {
    top: rem(-10);
    transition: top 75ms ease .12s, opacity 75ms ease;
  }
  &:after {
    bottom: rem(-10);
    transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19);
  }    
}

.collapse-nav {
  @media (max-width: 991px) {
    background-color: #1f1f1f;
    position: absolute;
    width: 100%;
  }
}

.card-nav {
  @media (max-width: 991px) {
    border-top: rem(1) solid rgba(255,255,255,.15);
  }
}

.nav-lang {
  li {
    &:not(:last-child) {
      margin-right: rem(20);
    }
  }
}